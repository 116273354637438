import React from 'react';
import { Link } from 'gatsby';
import './article-link.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ArticleLink = ({
  title, slug, date, imageData, group = 'articles',
}: any) => {
  const image = getImage(imageData);
  return (
    <div className="article-link">
      <Link to={`/${group}/${slug}`}>
        <div className="article-link-image">
          <GatsbyImage image={image} alt="" />
        </div>
        <div className="article-link-date">{date}</div>
        <div className="article-link-title" dir="auto">{title}</div>
      </Link>
    </div>
  );
};

export default ArticleLink;
