import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse, { escapeHTMLEntities } from '../utils/parse';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ArticleLink from '../components/article-link';

import './season.scss';

const DEFAULT_COLOR = [50, 50, 50];

interface Color {
  rgb: number[]
}

const generateGradient = (
  { rgb: rgb1 } : Color, { rgb: rgb2 }: Color,
) => `linear-gradient(
  to bottom, rgba(${rgb1.join(',')},.4), rgba(${rgb2.join(',')},.4))`;

const Season = ({ data, pageContext: { palette } }: any) => {
  const {
    page: {
      title, content, seoFields, featuredImage,
    },
    posts,
  } = data;

  const articles = posts?.edges;

  const customSeoDescription = seoFields?.customSeoDescription;
  const customSeoTitle = seoFields?.customSeoTitle;

  const image = getImage(featuredImage?.node?.localFile);

  const description = featuredImage?.node?.description || '';

  const { Vibrant: vibrantColor, LightVibrant: lightVibrantColor } = palette;
  const topColor = lightVibrantColor || vibrantColor || { rgb: DEFAULT_COLOR };
  const bottomColor = vibrantColor || { rgb: DEFAULT_COLOR };

  return (
    <Layout
      style={{
        backgroundImage: generateGradient(topColor, bottomColor),
        backgroundSize: 'auto',
      }}
      categories="Season"
    >
      <SEO
        title={customSeoTitle || title}
        metaTitle={customSeoTitle || title}
        image={image?.images.fallback.src}
        description={customSeoDescription}
      />
      <article className="article">
        <div className="article-inner">
          <div className="article-wrapper">
            <h2 className="article-title seasons-title" dir="auto">{title}</h2>
          </div>
          <div className="article-featured-image">
            <GatsbyImage image={image} alt={featuredImage?.node?.altText || ''} />
            <div className="article-featured-image-caption" dir="auto">
              {escapeHTMLEntities(description)}
            </div>
          </div>
          <div className="season-description" dir="auto">{parse(content)}</div>
          <div className="season-post-list">
            { articles.map(({
              node: {
                title: nodeTitle, slug, date, featuredImage: nodeImage,
              },
            }: any) => (
              <ArticleLink
                key={slug}
                title={nodeTitle}
                slug={slug}
                date={date}
                imageData={nodeImage?.node?.localFile}
              />
            ))}
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Season;

export const pageQuery = graphql`
query SeasonById(
  $id: String!
  $tag: String
) {
  # selecting the current post by id
  page: wpSeason(id: { eq: $id }) {
    content
    title

    seoFields {
      customSeoDescription
      customSeoTitle
    }

    featuredImage {
      node {
        altText
        description
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1390
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              aspectRatio: 1.7375
              quality: 96
            )
          }
        }
      }
    }
  }

  posts: allWpPost(filter: {tags: {nodes: {elemMatch: {slug: {eq: $tag}}}}}) {
    edges {
      node {
        title
        date(formatString: "DD.MM.YY")
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 675
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  aspectRatio: 1.7375
                  quality: 95
                )
              }
            }
          }
        }
      }
    }
  }
}
`;
